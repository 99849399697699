import React from "react";
import { useEffect } from "react";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./pages/home";
import PricingPage from "./pages/pricing";
import PolicyPage from "./pages/policy";
import TermsPage from "./pages/terms";
import ContactPage from "./pages/contact";

import { initializeAOS } from "../src/Utils/custom";

function App() {
  useEffect(() => {
    initializeAOS();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
