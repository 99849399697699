import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderLaptop = () => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    cssEase: "linear",
    arrows: false,
    dots: false,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className="laptop-slider">
      <picture>
        <img
    
          src="assets/front/images/banner/laptop1.webp"
          alt="laptop"
        />
      </picture>
      <picture>
        <img

          src="assets/front/images/banner/laptop2.webp"
          alt="laptop"
        />
      </picture>

      <picture>
        <img
         
          src="assets/front/images/banner/laptop3.webp"
          alt="laptop"
        />
      </picture>
    </Slider>
  );
};

export default SliderLaptop;
