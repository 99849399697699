import React from "react";
import { useEffect } from "react";
import { calculatePrice } from "../Utils/custom.js";

export default function Terms() {
  useEffect(() => {
    calculatePrice();
  }, []);

  return (
    <>
      <section className="privacy">
        <div>
          <h1>Ticketmetric LLC</h1>
          <p>
            <strong>Effective Date:</strong> May 30, 2024
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Introduction</h4>
          <p>
            Welcome to Ticketmetric! These Terms of Service ("Terms") govern
            your use of our platform, software, data, tools, including
            extensions, and any related services. By accessing or using our
            services, you agree to comply with these Terms.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Acceptance of Terms</h4>
          <p>
            By using Ticketmetric LLC, a Wyoming corporation ("we," "us,"
            "our"), and our services provided at ticketmetric.io, you agree to
            comply with and be bound by these Terms. If you do not agree to
            these Terms, please do not use our services or sign up.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Use of Services</h4>
          <ul>
            <li>
              You agree to provide accurate and complete information during
              registration and to update such information to keep it accurate
              and current.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account information and password.
            </li>
            <li>
              You must be at least 18 years old or have the legal capacity to
              enter into agreements to use our services.
            </li>
            <li>
              Ticketmetric reserves the right to modify, suspend, or discontinue
              any aspect of the services at any time without notice.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="privacy-heading">User Conduct</h4>
          <ul>
            <li>
              You are solely responsible for your conduct while using our
              services.
            </li>
            <li>
              You agree not to engage in any unlawful or prohibited activities
              while using the services.
            </li>
            <li>
              Some of the pictures and icons on our landing page or platform are
              for illustration purposes and may not be real.
            </li>
            <li>
              Some features that we advertise may not be fully ready even after
              the paid plans start.
            </li>
            <li>
              Ticketmetric may, at its discretion, terminate or suspend your
              access to the services for any reason, including suspected data
              scraping or unauthorized messaging of other users for advertising.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="privacy-heading">Beta Plan and Future Services</h4>
          <ul>
            <li>
              Ticketmetric offers a beta plan which is currently free of charge.
            </li>
            <li>
              Future services may include paid plans, and users will be notified
              of any changes to fees and payment structures.
            </li>
          </ul>
        </div>

        <div>
          <h4 className="privacy-heading">Data and Privacy</h4>
          <p>
            We take your privacy seriously. Please refer to our Privacy Policy
            for information on how we collect, use, and disclose personal
            information. Ticketmetric will process your data to provide
            services, conduct administrative and technical activities, and
            improve the platform.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Intellectual Property</h4>
          <p>
            All content, trademarks, and intellectual property on Ticketmetric’s
            website are owned by or licensed to Ticketmetric. You may not
            reproduce, distribute, or create derivative works from any content
            without our express written consent.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Affiliates and Resources</h4>
          <p>
            Ticketmetric may recommend various services and resources to our
            members and receive affiliate commissions in return. We may also
            integrate affiliate links anywhere on the website or tools.
            Resources include, but are not limited to, Partnerize and other
            marketplaces or companies.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Investment Disclaimer</h4>
          <p>
            Each investment made is your own decision. We only give tips, and
            losses on some tickets are inevitable. We are not responsible for
            any investment decisions you make. FTC Disclosure: Results Not
            Typical. We are not financial advisers. All decisions for tickets
            should be made by you personally.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Fees and Payments</h4>
          <p>
            You agree to pay all applicable fees for using Ticketmetric’s
            services as presented upon registration. All fees are quoted in US
            Dollars and are non-refundable.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Limitation of Liability</h4>
          <p>
            Ticketmetric is not liable for any direct, indirect, incidental,
            special, or consequential damages resulting from the use or
            inability to use the services. We do not guarantee the accuracy,
            completeness, or timeliness of the data or services.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Changes to Terms</h4>
          <p>
            Ticketmetric reserves the right to update or modify these Terms at
            any time without prior notice. Your continued use of the services
            after any changes constitutes acceptance of the modified Terms.
          </p>
        </div>

        <div>
          <h4 className="privacy-heading">Contact Information</h4>
          <p>
            If you have any questions or concerns regarding these Terms, please
            contact Ticketmetric at{" "}
            <a href="mailto:support@ticketmetric.io">support@ticketmetric.io</a>
            .
          </p>
        </div>

        <div>
          <h4 className="privacy-heading"></h4>
          <h4 className="privacy-heading"></h4>
          <h4 className="privacy-heading"></h4>
          <h4 className="privacy-heading"></h4>
          <h4 className="privacy-heading"></h4>
          <p>
            By agreeing to these terms of service, you consent to receive
            promotional emails and updates from ticketmetric about our products
            and services. You can unsubscribe from these emails at any time by
            following the unsubscribe link included in every email.
          </p>
        </div>
      </section>
    </>
  );
}
