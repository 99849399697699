import React from "react";
import { useEffect } from "react";
import { calculatePrice } from "../Utils/custom.js";

export default function Policy() {
  useEffect(() => {
    calculatePrice();
  }, []);

  return (
    <>
      <section className="privacy">
        <div>
          <h1>Ticketmetric LLC</h1>
          <p>
            <strong>Effective Date:</strong> May 30, 2024
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Introduction</h4>
          <p>
            This Privacy Policy outlines how Ticketmetric LLC ("we," "us,"
            "our") collects, uses, and safeguards your personal information when
            you use our website, extension, and related services. By accessing
            or using Ticketmetric’s services, you agree to this Privacy Policy.
            Ticketmetric is committed to protecting your privacy.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Information Collection</h4>
          <ul>
            <li>
              We may collect personal details such as your name, email address,
              postal address, phone number, and payment information when you
              register or interact with our services.
            </li>
            <li>
              We may collect data regarding your use of our website or services,
              including browsing history, IP address, device information, the
              features you use, and the actions you take.
            </li>
          </ul>
        </div>

        <div>
          <h4 class="privacy-heading">Use of Collected Information</h4>
          <p>
            We utilize the information to provide, maintain, and enhance our
            services, process transactions, and personalize your user
            experience. Ticketmetric may use your email to send updates,
            promotions, and relevant information. You may opt out of these
            communications at any time.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Information Sharing</h4>
          <ul>
            <li>
              We do not sell, trade, or rent your personal information to third
              parties without your explicit consent.
            </li>
            <li>
              We may disclose your information as required by law to enforce our
              policies, respond to claims, or protect the rights, property, or
              safety of Ticketmetric, its users, or others.
            </li>
          </ul>
        </div>

        <div>
          <h4 class="privacy-heading">Security Measures</h4>
          <p>
            We implement industry-standard security measures to protect your
            personal information. However, no internet or electronic storage
            method is entirely secure. Thus, we cannot guarantee absolute
            security.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Cookies and Tracking Technologies</h4>
          <p>
            Ticketmetric uses cookies and similar technologies to improve user
            experience and analyze website usage. You can manage cookie
            preferences through your browser settings.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Your Rights and Choices</h4>
          <p>
            You have the right to access, correct, or delete your personal
            information at any time. Choosing not to provide certain information
            may limit your ability to use some features of our services.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Children’s Privacy</h4>
          <p>
            Ticketmetric does not knowingly collect information from children
            under 18. If we discover that we have collected information from a
            child under 18, we will promptly delete it.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Changes to this Privacy Policy</h4>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any changes by posting the updated
            policy on our website. Continued use of our services after such
            changes constitutes acceptance of the new policy.
          </p>
        </div>

        <div>
          <h4 class="privacy-heading">Contact Information</h4>
          <p>
            For questions or concerns regarding this Privacy Policy, please
            contact Ticketmetric at{" "}
            <a href="mailto:support@ticketmetric.io">support@ticketmetric.io</a>
            .
          </p>
        </div>
      </section>
    </>
  );
}
