import React from "react";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Counter from "../components/Counter";
import SliderLogos from "../components/SliderLogos";
import Features from "../components/Features";
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";
import { menu_home_change } from "../Utils/custom";
import { Helmet } from "react-helmet";

export default function HomePage() {
  useEffect(() => {
    menu_home_change();
  }, []);

  return (
    <>
      <Helmet>
        <title>Ticketmetric | Ticket Flipping Analytics Software For Brokers</title>
        <meta name="description" content="Ticketmetric data analytics ticket flipping software for ticket brokers to flip better, faster, & smarter. Maximize profits & efficiency with our advanced tools" />
      </Helmet>
      <Navbar />
      <Banner />
      <Counter />
      <SliderLogos />
      <Features />
      <Calltoaction />
      <Footer />
    </>
  );
}