import React from "react";

import Navbar from "../components/Navbar";
import PriceBanner from "../components/Pricingbanner";
import Policy from "../components/Policy";
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";

export default function PolicyPage() {
  return (
    <>
      <Navbar />
      <PriceBanner />
      <Policy />
      <Calltoaction />
      <Footer />
    </>
  );
}
