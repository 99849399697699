import React from "react";
import { useEffect } from "react";
import { calculatePrice } from "../Utils/custom.js";

export default function Contacts() {
  useEffect(() => {
    calculatePrice();
  }, []);

  return (
    <>
      <section className="privacy">
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <p style={{ color: "blue" }}>
            We are trying to provide support to our community around the clock,
            so drop us a message anytime and we will try to answer you 24/7.
          </p>
          <h4 className="privacy-heading">Do you need help with anything?</h4>
          <p>
            Just drop us a message at{" "}
            <a
              href="mailto:support@ticketmetric.io"
              style={{ textDecoration: "none" }}
            >
              support@ticketmetric.io
            </a>
          </p>
          <p>
            Or open a ticket under the support channel on{" "}
            <a
              href="https://discord.gg/gQaYSfyyfU"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
          </p>
        </div>
      </section>
    </>
  );
}
