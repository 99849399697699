import React from "react";

import Navbar from "../components/Navbar";
import PriceBanner from "../components/Pricingbanner";
import Contact from "../components/Contact";
import Calltoaction from "../components/Calltoaction";
import Footer from "../components/Footer";

export default function ContactsPage() {
  return (
    <>
      <Navbar />
      <PriceBanner />
      <Contact />
      <Calltoaction />
      <Footer />
    </>
  );
}
