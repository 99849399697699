import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderLogos = () => {
  const settings = {

    dots: false,
    arrows: false,
    infinite: true,
    speed: 15000,
    slidesToShow: 3,
    slidesToScroll: 8,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    swipeToSlide: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:1,
          slidesToScroll: 8,
        },
      },
    ]
  };

  return (
    <>
      <section className="logo-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Slider {...settings} className="logos-slider slider">
              <div className="slide">
                  <img
                    className="img-fluid"
                    src="assets/front/images/logos/1.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid"
                    src="assets/front/images/logos/2.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid"
                    src="assets/front/images/logos/3.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid mob-small"
                    src="assets/front/images/logos/4.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid"
                    src="assets/front/images/logos/5.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid"
                    src="assets/front/images/logos/6.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid mob-small"
                    src="assets/front/images/logos/7.png"
                    alt=""
                  />
                </div>
                <div className="slide">
                  <img
                    className="img-fluid mob-small"
                    src="assets/front/images/logos/8.png"
                    alt=""
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderLogos;
