import $ from "jquery";
import * as AOS from "aos";

export const initializeAOS = () => {
  AOS.init({
    easing: "ease-out-back",
    duration: 1200,
  });

  var btnToTop = $("#btnToTop");
  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
      btnToTop.addClass("show");
    } else {
      btnToTop.removeClass("show");
    }
  });

  btnToTop.on("click", function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "300");
  });
};

export const clickMenu = () => {
  document.getElementById("mobile-nav-btn").onclick = function () {
    let element = document.getElementById("menu-wrap");

    if (element.classList.contains("active")) {
      element.classList.remove("active");
    } else {
      element.classList.add("active");
    }
  };
};

export const clickMenulist = () => {
  let navLinks = document.querySelectorAll(".mob-link");
  let element = document.getElementById("menu-wrap");
  Array.from(navLinks).forEach((link) => {
    link.addEventListener("click", function (event) {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
    });
  });
};

export const calculatePrice = () => {
  $("#pricemonth").on("click", function (e) {
    $(".year").removeClass("active");
    $(".month").addClass("active");
    // if ($(this).is(':checked')) alert('is checked');
    $(".price-list-row  .price .price-yearly").addClass("d-none");
    $(".price-list-row  .price .price-monthly").removeClass("d-none");
    $(".price-list-row  .price .price-monthly").addClass("show");

    $(".price-list-row  .btn-wrapper .pay-link-yearly").addClass("d-none");
    $(".price-list-row  .btn-wrapper .pay-link-monthly").removeClass("d-none");
    $(".price-list-row  .btn-wrapper .pay-link-monthly").addClass("show");
  });
  $("#priceyear").on("click", function (e) {
    $(".month").removeClass("active");
    $(".year").addClass("active");
    $(".price-list-row  .price .price-monthly").addClass("d-none");
    $(".price-list-row  .price .price-yearly").removeClass("d-none");
    $(".price-list-row  .price .price-yearly").addClass("show");

    $(".price-list-row  .btn-wrapper .pay-link-monthly").addClass("d-none");
    $(".price-list-row  .btn-wrapper .pay-link-yearly").removeClass("d-none");
    $(".price-list-row  .btn-wrapper .pay-link-yearly").addClass("show");
  });
};

export const menu_home_change = () => {
  $("#software").on("click", function (e) {
    $("#EventAnalytics-tab").trigger("click");
  });

  $("#toolsExtension").on("click", function (e) {
    $("#Extension-tab").trigger("click");
  });

  $("#artistAnalytics").on("click", function (e) {
    $("#ArtistMetric-tab").trigger("click");
  });

  $("#mob-software").on("click", function (e) {
    $("#tab-munu-select").val("2");
    $("#EventAnalytics-tab").trigger("click");
  });

  $("#mob-toolsExtension").on("click", function (e) {
    $("#tab-munu-select").val("5");
    $("#Extension-tab").trigger("click");
  });

  $("#mob-artistAnalytics").on("click", function (e) {
    $("#tab-munu-select").val("4");
    $("#ArtistMetric-tab").trigger("click");
  });
};

export const featureMenuChange = () => {
  document
    .getElementById("tab-munu-select")
    .addEventListener("change", function () {
      if (this.value === "1") {
        document.getElementById("UpcomingSales-tab").click();
      } else if (this.value === "2") {
        document.getElementById("EventAnalytics-tab").click();
      } else if (this.value === "3") {
        document.getElementById("Drop-tab").click();
      } else if (this.value === "4") {
        document.getElementById("ArtistMetric-tab").click();
      } else if (this.value === "5") {
        document.getElementById("Extension-tab").click();
      } else if (this.value === "6") {
        document.getElementById("Community-tab").click();
      }

      let tab_section = document.getElementById("features");
      scrollTo(tab_section);
      function scrollTo(element) {
        window.scroll({
          behavior: "smooth",
          top: element.offsetTop,
        });
      }
    });
};

export const counterNumbers = () => {
  var a = 0;
  $(window).scroll(function () {
    if ($("#counter").length > 0) {
      // Exists.

      var oTop = $("#counter").offset().top - window.innerHeight;
      if (a === 0 && $(window).scrollTop() > oTop) {
        $(".counter").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-target");
          $({
            countNum: $this.text(),
          }).animate(
            {
              countNum: countTo,
            },

            {
              duration: 2000,
              easing: "swing",
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
              },
            }
          );
        });
        a = 1;
      }
    }
  });
};

export const tabFeaturebuttons = () => {
  AOS.init({
    easing: "ease-out-back",
    duration: 1200,
  });

  let tab_Links = document.querySelectorAll(".nav-tabs button");
  let tab_section = document.getElementById("tab-featureContent");
  Array.from(tab_Links).forEach((link) => {
    link.addEventListener("click", function (event) {
      scrollTo(tab_section);
      function scrollTo(element) {
        window.scroll({
          behavior: "smooth",
          top: element.offsetTop,
        });
      }
    });
  });
};
